import React, { Component } from "react";
import { OrgDiagram } from "basicprimitivesreact";
import Modal from "react-modal";
import {
  PageFitMode,
  Colors,
  ItemType,
  AdviserPlacementType,
  Enabled,
  AnnotationType,
  ChildrenPlacementType
} from "basicprimitives";
import "./Team.css";

const JOB_DESCRIPTIONS = {
  "Web Developer": "https://organization.lascasasgaming.com/webdev_jd.pdf",
  "Software Developer": "https://organization.lascasasgaming.com/softdev_jd.pdf",
  "IT Specialist": "https://organization.lascasasgaming.com/itspec_jd.pdf",
  "IT Infrastructure": "https://organization.lascasasgaming.com/infra_jd.pdf",
  "Junior Developer": "https://organization.lascasasgaming.com/jr_dev_jd.pdf",
  "Senior Developer": "https://organization.lascasasgaming.com/sr_web_dev_jd.pdf",
  "Mobile App Developer": "https://organization.lascasasgaming.com/mob_app_dev_jd.pdf",
  "Customer Service Representative": "https://organization.lascasasgaming.com/csr_jd.pdf",
  "Multimedia Artist": "https://organization.lascasasgaming.com/multi_jd.pdf",
  "IT Head": "https://organization.lascasasgaming.com/it_head_jd.pdf",
  "Marketing Head": "https://organization.lascasasgaming.com/marketing_head_jd.pdf",
  "Marketing Supervisor": "https://organization.lascasasgaming.com/marketing_super_jd.pdf",
  "Marketing Brand Officer": "https://organization.lascasasgaming.com/marketing_brand_jd.pdf",
  "Marketing Digital": "https://organization.lascasasgaming.com/marketing_digital_jd.pdf",
  "Marketing Staff": "https://organization.lascasasgaming.com/marketing_staff_jd.pdf",
  "Sales Head": "https://organization.lascasasgaming.com/sales_head_jd.pdf",
  "HR Admin Assist": "https://organization.lascasasgaming.com/hr_admin_asst_jd.pdf",
  "Vice President": "https://organization.lascasasgaming.com/vice_pres_jd.pdf",
  "President": "https://organization.lascasasgaming.com/president_jd.pdf",
  "Finance Head": "https://organization.lascasasgaming.com/finance_head_jd.pdf",
  "Utility Personnel": "https://organization.lascasasgaming.com/utility_person_jd.pdf",
  "CSR Head": "https://organization.lascasasgaming.com/csr_head_jd.pdf",
  "EA Vice President": "https://organization.lascasasgaming.com/ea_vice_pres_jd.pdf",
  "EA President": "https://organization.lascasasgaming.com/ea_president_jd.pdf",
  "Public Relations": "https://organization.lascasasgaming.com/public_relations_jd.pdf",
  "Copywriter": "https://organization.lascasasgaming.com/copywriter_jd.pdf",
  "Gaming Finance Analyst": "https://organization.lascasasgaming.com/finance_analyst_jd.pdf",
  "Gaming Site Staff": "https://organization.lascasasgaming.com/gaming_site_staff_jd.pdf",
  "General Accounts": "https://organization.lascasasgaming.com/general_accounts_jd.pdf",
  "HR Head": "https://organization.lascasasgaming.com/hr_head_jd.pdf",
};

const JOB_KPI = {
  "IT Head": "https://organization.lascasasgaming.com/it_head_kpi.pdf",
  "IT Specialist": "https://organization.lascasasgaming.com/itspec_kpi.pdf",
  "IT Infrastructure": "https://organization.lascasasgaming.com/infra_kpi.pdf",
  "Junior Developer": "https://organization.lascasasgaming.com/jr_dev_kpi.pdf",
  "Senior Developer": "https://organization.lascasasgaming.com/sr_web_dev_kpi.pdf",
  "Mobile App Developer": "https://organization.lascasasgaming.com/mob_app_dev_kpi.pdf",
  "Customer Service Representative": "https://organization.lascasasgaming.com/csr_kpi.pdf",
  "Multimedia Artist": "https://organization.lascasasgaming.com/multi_kpi.pdf",
  "Marketing Head": "https://organization.lascasasgaming.com/marketing_head_kpi.pdf",
  "Marketing Supervisor": "https://organization.lascasasgaming.com/marketing_super_kpi.pdf",
  "Marketing Brand Officer": "https://organization.lascasasgaming.com/marketing_brand_kpi.pdf",
  "Marketing Digital": "https://organization.lascasasgaming.com/marketing_digital_kpi.pdf",
  "Marketing Staff": "https://organization.lascasasgaming.com/marketing_staff_kpi.pdf",
  "Sales Head": "https://organization.lascasasgaming.com/sales_head_kpi.pdf",
  "HR Admin Assist": "https://organization.lascasasgaming.com/hr_admin_asst_kpi.pdf",
  "Vice President": "https://organization.lascasasgaming.com/vice_pres_kpi.pdf",
  "President": "https://organization.lascasasgaming.com/president_kpi.pdf",
  "Finance Head": "https://organization.lascasasgaming.com/finance_head_kpi.pdf",
  "Utility Personnel": "https://organization.lascasasgaming.com/utility_person_kpi.pdf",
  "CSR Head": "https://organization.lascasasgaming.com/csr_head_kpi.pdf",
  "EA Vice President": "https://organization.lascasasgaming.com/ea_vice_pres_kpi.pdf",
  "EA President": "https://organization.lascasasgaming.com/ea_president_kpi.pdf",
  "Public Relations": "https://organization.lascasasgaming.com/public_relations_kpi.pdf",
  "Copywriter": "https://organization.lascasasgaming.com/copywriter_kpi.pdf",
  "Gaming Finance Analyst": "https://organization.lascasasgaming.com/finance_analyst_kpi.pdf",
  "Gaming Site Staff": "https://organization.lascasasgaming.com/gaming_site_staff_kpi.pdf",
  "General Accounts": "https://organization.lascasasgaming.com/general_accounts_kpi.pdf",
  "HR Head": "https://organization.lascasasgaming.com/hr_head_kpi.pdf",
};

const ORGCHART = "https://organization.lascasasgaming.com/images/orgchart_lcgi.jpeg"

class Team extends Component {
  constructor(props) {
    super(props);

    this.handleResize = this.handleResize.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      modalIsOpen: false,
      selectedItem: null,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  openModal = (item) => {
    this.setState({ modalIsOpen: true, selectedItem: item });
  };

  closeModal() {
    this.setState({ modalIsOpen: false, selectedItem: null });
  }

  downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };


  render() {
    var photos = {
      a: "/images/default_user.jpg",
      //IT
      b1: "/images/alvin.jpg",
      b2: "/images/nesty.jpeg",
      b3: "/images/mash.png",
      b4: "/images/rafael.jpeg",
      b5: "/images/dap.jpg",
      b6: "/images/dan.jpeg",
      b7: "/images/gino.jpeg",
      //CSR
      c1: "/images/bebs.jpeg",
      c2: "/images/kevin.png",
      c3: "/images/danica.jpeg",
      c4: "/images/karen.jpeg",
      c5: "/images/silva.jpeg",
      c6: "/images/josh.jpeg",
      c7: "/images/grace.jpeg",
      c8: "/images/john_csr.jpg",
      //FINANCE
      d1: "/images/alan.jpeg",
      d2: "/images/justin.jpg",
      d3: "/images/bryan.jpg",
      //HR
      e1: "/images/default_user.jpg",
      e2: "/images/step.jpg",
      e3: "/images/arjay.jpeg",
      e4: "images/jerome_angelo.jpeg",
      e5: "images/nestor.jpg",
      //MARKETING
      f1: "/images/sharmaine.jpeg",
      f2: "/images/jerome.jpeg",
      f3: "/images/camil.png",
      f4: "/images/erik.jpeg",
      f5: "/images/jane.jpg",
      f6: "/images/bernard.jpg",
      f7: "/images/jayce.png",
      f8: "/images/kyle.jpg",
      f9: "/images/angelo.png",
      //BOSS
      g1: "/images/joms.jpeg",
      g2: "/images/kenniedy.jpeg",
      g3: "/images/ana.jpeg",
      g4: "/images/alphie.png",
      g5: "/images/leo.jpeg",
      g6: "/images/gian.jpeg",
      g7: "/images/rina.jpeg",
      g8: "/images/dara.jpg",
    };

    const { width, height, modalIsOpen, selectedItem } = this.state;
    const config = {
      annotations: [
        {
          annotationType: AnnotationType.HighlightPath,
          items: [6, 20],
          color: Colors.Red,
          lineWidth: 2,
          opacity: 1,
          showArrows: true
        }
      ],
      pageFitMode: PageFitMode.AutoSize,
      highlightItem: 0,
      cursorItem: 2,
      linesWidth: 2,
      linesColor: "black",
      hasSelectorCheckbox: Enabled.False,
      autoSizeMinimum: { width: 0, height: 0 },
      autoSizeMaximum: { width: width, height: height },
      templates: [
        {
          name: "DepartmentTitleTemplate",
          isActive: false,
          itemSize: { width: 100, height: 30 },
          minimizedItemSize: { width: 3, height: 3 },
          highlightPadding: { left: 2, top: 2, right: 2, bottom: 2 },
          onItemRender: ({ context: itemConfig }) => {
            const itemTitleColor =
              itemConfig.itemTitleColor != null
                ? itemConfig.itemTitleColor
                : Colors.Green;
            return (
              <div className="DepartmentTemplate">
                <div
                  className="DepartmentTitleBackground"
                  style={{ backgroundColor: itemTitleColor }}
                >
                  <div className="DepartmentTitle">{itemConfig.title}</div>
                </div>
              </div>
            );
          },
        },
        //ITEMS
        {
          name: "contactTemplate",
          itemSize: { width: 200, height: 100 },
          onItemRender: ({ context: item }) => {
            const itemTitleColor =
              item.itemTitleColor != null
                ? item.itemTitleColor
                : Colors.RoyalBlue;
            return (
              <div
                className="ContactTemplate"
                onClick={() => this.openModal(item)}
              >
                <div
                  className="ContactTitleBackground"
                  style={{ backgroundColor: itemTitleColor }}
                >
                  <div className="ContactTitle">{item.title}</div>
                </div>
                <div>
                  <img
                    className="ContactPhoto"
                    src={item.image}
                    alt={item.title}
                  />
                  <div className="ContactDescription">{item.description}</div>
                </div>
              </div>
            );
          },
        },
        {
          name: "contactTemplate2",
          itemSize: { width: 200, height: 100 },
          onItemRender: ({ context: item }) => {
            const itemTitleColor =
              item.itemTitleColor != null
                ? item.itemTitleColor
                : Colors.RoyalBlue;
            return (
              <div
                className="ContactTemplate2"
                onClick={() => this.openModal(item)}
              >
                <div
                  className="ContactTitleBackground"
                  style={{ background: "linear-gradient(90deg, royalblue 50%, green 50%)" }}
                >
                  <div className="ContactTitle">{item.title}</div>
                </div>
                <div>
                  <img
                    className="ContactPhoto"
                    src={item.image}
                    alt={item.title}
                  />
                  <div className="ContactDescription">{item.description}</div>
                </div>
              </div>
            );
          },
        },
        //HEADS
        {
          name: "contactTemplate3",
          itemSize: { width: 200, height: 100 },
          onItemRender: ({ context: item }) => {
            const itemTitleColor =
              item.itemTitleColor != null
                ? item.itemTitleColor
                : Colors.RoyalBlue;
            return (
              <div
                className="ContactTemplate2"
                onClick={() => this.openModal(item)}
              >
                <div
                  className="ContactTitleBackground"
                  style={{ background: "linear-gradient(90deg, orange 50%, green 50%)" }}
                >
                  <div className="ContactTitle">{item.title}</div>
                </div>
                <div>
                  <img
                    className="ContactPhoto"
                    src={item.image}
                    alt={item.title}
                  />
                  <div className="ContactDescription">{item.description}</div>
                </div>
              </div>
            );
          },
        },
        //HEADS-LEO
        {
          name: "contactTemplate4",
          itemSize: { width: 200, height: 100 },
          onItemRender: ({ context: item }) => {
            const itemTitleColor =
              item.itemTitleColor != null
                ? item.itemTitleColor
                : Colors.RoyalBlue;
            return (
              <div
                className="ContactTemplate2"
                onClick={() => this.openModal(item)}
              >
                <div
                  className="ContactTitleBackground"
                  style={{ background: "linear-gradient(90deg, yellow 50%, green 50%)" }}
                >
                  <div className="ContactTitle">{item.title}</div>
                </div>
                <div>
                  <img
                    className="ContactPhoto"
                    src={item.image}
                    alt={item.title}
                  />
                  <div className="ContactDescription">{item.description}</div>
                </div>
              </div>
            );
          },
        },
      ],
      items: [
        {
          id: 0,
          parent: null,
          title: "Jose Maria Acuzar",
          description: "Chairman",
          image: photos.g1,
          templateName: "contactTemplate",
        },
        {
          id: 1,
          parent: 0,
          title: "Kenniedy Francisco",
          description: "EA to the Chairman",
          itemType: ItemType.Adviser,
          adviserPlacementType: AdviserPlacementType.Right,
          image: photos.g2,
          itemTitleColor: Colors.Green,
          templateName: "contactTemplate",
        },
        {
          id: 2,
          parent: 0,
          title: "Analiza Mangco",
          description: "Corporate Secretary",
          image: photos.g3,
          itemTitleColor: "#FFA600",
          templateName: "contactTemplate",
        },
        {
          id: 3,
          parent: 2,
          title: "Alphie Tablazon",
          description: "EA to Corporate Secretary",
          itemType: ItemType.Adviser,
          adviserPlacementType: AdviserPlacementType.Left,
          image: photos.g4,
          itemTitleColor: Colors.Green,
          templateName: "contactTemplate",
        },
        {
          id: 4,
          parent: 0,
          title: "Leo Barrosa",
          jd_pdf: "President",
          description: "President",
          image: photos.g5,
          templateName: "contactTemplate4",
          jobDescription0: "Position Title: President",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Jose Maria Acuzar",
        },
        {
          id: 5,
          parent: 4,
          title: "Gianne Fernando",
          jd_pdf: "EA President",
          description: "EA to the President",
          itemType: ItemType.Adviser,
          adviserPlacementType: AdviserPlacementType.Right,
          image: photos.g6,
          itemTitleColor: Colors.Green,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: EA to the President",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Leo Barrosa",
        },
        {
          id: 6,
          parent: 4,
          title: "Maria Celerina Gallardo III",
          jd_pdf: "Vice President",
          description: "Vice President - Operations",
          image: photos.g7,
          templateName: "contactTemplate3",
          jobDescription0: "Position Title: Vice President - Operations",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Jose Maria Acuzar & Leo Barrosa",
        },
        {
          id: 26,
          parent: 6,
          title: "Dayanara Reyes-De Guzman",
          jd_pdf: "EA Vice President",
          description: "EA to the Vice President",
          itemType: ItemType.Adviser,
          adviserPlacementType: AdviserPlacementType.Right,
          image: photos.g8,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: EA to the Vice President",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III - Vice President for Operations",
        },
        {
          id: 7,
          parent: 6,
          title: "Gino Rafael Dimaano",
          jd_pdf: "IT Head",
          description: "IT Head",
          image: photos.b7,
          itemTitleColor: Colors.RoyalBlue,
          templateName: "contactTemplate3",
          jobDescription0: "Position Title: IT Head",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III - Vice President for Operations",
        },
        {
          id: 8,
          parent: 6,
          title: "Alan Harold Azada",
          jd_pdf: "Finance Head",
          description: "Finance Head - Shared Services",
          image: photos.d1,
          itemTitleColor: Colors.Green,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Finance Head",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Jose Maria Acuzar, Leo Barrosa & Maria Celerina Gallardo III",
        },
        {
          id: 9,
          parent: 6,
          title: "Sharmaine Marie Abarquez",
          jd_pdf: "Marketing Head",
          description: "Marketing/CSR Head",
          image: photos.f1,
          templateName: "contactTemplate3",
          jobDescription0: "Position Title: Marketing/CSR Head",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III - Vice President for Operations",
        },
        
        {
          id: 20,
          parent: 6,
          title: "Vacant",
          jd_pdf: "CSR Head",
          description: "CSR Head",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          childrenPlacementType: ChildrenPlacementType.Vertical,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: CSR Head",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III - Vice President for Operations",
        },
        {
          id: 10,
          parent: 6,
          title: "Vacant",
          jd_pdf: "HR Head",
          description: "Admin/HR Head",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Admin/HR Head",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 11,
          parent: 6,
          title: "Vacant",
          jd_pdf: "Sales Head",
          description: "Sales Head",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          childrenPlacementType: ChildrenPlacementType.Vertical,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Sales Head",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 12,
          parent: 11,
          title: "Gaming Sites",
          jd_pdf: "Gaming Site Staff",
          description: "Gaming Site Staff",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Gaming Site Staff",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 13,
          parent: 11,
          title: "Gaming Sites",
          jd_pdf: "Gaming Site Staff",
          description: "Gaming Site Staff",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Gaming Site Staff",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 14,
          parent: 11,
          title: "Gaming Sites",
          jd_pdf: "Gaming Site Staff",
          description: "Gaming Site Staff",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Gaming Site Staff",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 15,
          parent: 11,
          title: "Gaming Sites",
          jd_pdf: "Gaming Site Staff",
          description: "Gaming Site Staff",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Gaming Site Staff",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 16,
          parent: 11,
          title: "Gaming Sites",
          jd_pdf: "Gaming Site Staff",
          description: "Gaming Site Staff",
          image: photos.e1,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Gaming Site Staff",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 17,
          parent: 10,
          title: "Stephanie Jane Guias",
          jd_pdf: "HR Admin Assist",
          description: "Admin/HR Assistant",
          image: photos.e2,
          childrenPlacementType: ChildrenPlacementType.Vertical,
          templateName: "contactTemplate2",
          itemTitleColor: Colors.Green,
          jobDescription0: "Position Title: Admin/HR Assistant",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III / Admin/HR Head",
        },
        {
          id: 18,
          parent: 17,
          title: "Jerome Angielo Punzal",
          jd_pdf: "Utility Personnel",
          description: "Utility Personnel/Housekeeper",
          image: photos.e4,
          levelOffset: 1,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Utility Personnel/Housekeeper",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Stephanie Jane Guias",
        },
        {
          id: 54,
          parent: 17,
          title: "Nestor T. Pellazar",
          jd_pdf: "Driver",
          description: "Driver",
          image: photos.e5,
          levelOffset: 1,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Driver",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Stephanie Jane Guias",
        },
        {
          id: 55,
          parent: 17,
          title: "Driver",
          jd_pdf: "Driver",
          description: "Driver 2",
          image: photos.a,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Driver",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Stephanie Jane Guias",
        },
        {
          id: 56,
          parent: 17,
          title: "Driver",
          jd_pdf: "Driver",
          description: "Driver 3",
          image: photos.a,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Driver",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Stephanie Jane Guias",
        },
        {
          id: 19,
          parent: 9,
          title: "Jerome Isaac Maniquis",
          jd_pdf: "Marketing Supervisor",
          description: "Marketing Supervisor",
          image: photos.f2,
          itemTitleColor: "#ccc",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Marketing Supervisor",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        { id: 30, parent: 9, isVisible: false },
        { id: 31, parent: 9, isVisible: false },
        { id: 32, parent: 9, isVisible: false },
        {
          id: 21,
          parent: 19,
          templateName: "DepartmentTitleTemplate",
          title: "ACTIVATIONS",
          childrenPlacementType: ChildrenPlacementType.Vertical,
          itemTitleColor: "Yellow",
        },
        {
          id: 59,
          parent: 21,
          title: "Michael Angelo Ramos",
          jd_pdf: "#",
          // itemTitleColor: "#FF2A2A",
          description: "Brand Officer/Activations",
          image: photos.f9,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Activations Support",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 22,
          parent: 21,
          title: "Camille Premia",
          jd_pdf: "Marketing Staff",
          description: "Activations Support",
          image: photos.f3,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Activations Support",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 23,
          parent: 30,
          templateName: "DepartmentTitleTemplate",
          childrenPlacementType: ChildrenPlacementType.Vertical,
          title: "BRANDING",
          itemTitleColor: "Yellow",
        },
        {
          id: 24,
          parent: 31,
          templateName: "DepartmentTitleTemplate",
          childrenPlacementType: ChildrenPlacementType.Vertical,
          title: "DIGITAL",
          itemTitleColor: "Yellow",
        },
        {
          id: 25,
          parent: 32,
          templateName: "DepartmentTitleTemplate",
          childrenPlacementType: ChildrenPlacementType.Vertical,
          title: "CREATIVES",
          itemTitleColor: "Yellow",
        },
        {
          id: 27,
          parent: 23,
          title: "Ma. Erika Nisnisan",
          jd_pdf: "Marketing Brand Officer",
          description: "Brand Officer/Writer",
          image: photos.f4,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Brand Officer/Writer",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 28,
          parent: 24,
          title: "Jane Marie Abenoja",
          jd_pdf: "Marketing Digital",
          description: "Digital Brand Officer",
          image: photos.f5,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Digital Brand Officer",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 29,
          parent: 25,
          title: "Bernandino Lozano",
          jd_pdf: "Multimedia Artist",
          description: "Multimedia Artist",
          image: photos.f6,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Multimedia Artist",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 33,
          parent: 25,
          title: "Michael Adriane Salazar",
          jd_pdf: "Multimedia Artist",
          description: "Multimedia Artist",
          image: photos.f8,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Multimedia Artist",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },

        {
          id: 80,
          parent: 25,
          title: "Jayce Aron Go",
          jd_pdf: "Multimedia Artist",
          description: "Multimedia Artist",
          image: photos.f7,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Multimedia Artist",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 35,
          parent: 24,
          title: "Vacant",
          jd_pdf: "Copywriter",
          description: "Copywriter",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Copywriter",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 36,
          parent: 23,
          title: "Vacant",
          jd_pdf: "Public Relations",
          description: "Public Relations",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Public Relations",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Sharmaine Marie Abarquez",
        },
        {
          id: 37,
          parent: 20,
          templateName: "DepartmentTitleTemplate",
          title: "CSR",
          itemTitleColor: "Yellow",
        },
        {
          id: 38,
          parent: 20,
          title: "Beverly Abas",
          jd_pdf: "Customer Service Representative",
          description: "Customer Service Representative",
          image: photos.c1,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Customer Service Representative",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3: "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 39,
          parent: 20,
          title: "Mark Kevin Pedregosa",
          jd_pdf: "Customer Service Representative",
          description: "Customer Service Representative",
          image: photos.c2,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Customer Service Representative",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3: "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 40,
          parent: 20,
          title: "Danica Clier Cana",
          jd_pdf: "Customer Service Representative",
          description: "Customer Service Representative",
          image: photos.c3,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Customer Service Representative",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3: "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 58,
          parent: 20,
          title: "Mary Grace Sygaco",
          jd_pdf: "Customer Service Representative",
          description: "Customer Service Representative",
          image: photos.c7,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Customer Service Representative",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3: "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 42,
          parent: 51,
          title: "John Michael Silva",
          jd_pdf: "#",
          description: "Technical Support",
          image: photos.c5,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Technical Support",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3: "Reports to : Gino Rafael Dimaano",
        },{
          id: 57,
          parent: 20,
          title: "John Fernandez",
          jd_pdf: "Customer Service Representative",
          description: "Customer Service Representative",
          image: photos.c8,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Technical Support",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3: "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 43,
          parent: 20,
          title: "Joshua Fontiveros",
          jd_pdf: "Customer Service Representative",
          description: "Customer Service Representative",
          image: photos.c6,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Customer Service Representative",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3: "Reports to : Maria Celerina Gallardo III",
        },
        {
          id: 44,
          parent: 8,
          title: "Justin Nemis",
          jd_pdf: "General Accounts",
          description: "General Accounts - Shared Services",
          image: photos.d2,
          itemTitleColor: Colors.Green,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: General Accounts",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III / Alan Azada",
        },
        {
          id: 45,
          parent: 44,
          title: "Bryan Emmanuel Galos",
          jd_pdf: "General Accounts",
          description: "General Accounts - Shared Services",
          image: photos.d3,
          itemTitleColor: Colors.Green,
          templateName: "contactTemplate",
          jobDescription0: "Position Title: General Accounts",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III / Alan Azada",
        },
        {
          id: 46,
          parent: 45,
          title: "Vacant",
          jd_pdf: "Gaming Finance Analyst",
          description: "Gaming Finance Analyst",
          image: photos.a,
          itemTitleColor: "#FF2A2A",
          templateName: "contactTemplate",
          jobDescription0: "Position Title: Gaming Finance Analyst",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Maria Celerina Gallardo III / Alan Azada",
        },
        {
          id: 48,
          parent: 7,
          title: "Mashiyyat V. Delos Santos",
          jd_pdf: "Senior Developer",
          description: "Sr. Application/Web Developer",
          childrenPlacementType: ChildrenPlacementType.Vertical,
          image: photos.b3,
          templateName: "contactTemplate",
          itemTitleColor: Colors.Green,
          jobDescription0: "Position Title: Sr. Web Developer Supervisor",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Gino Rafael Dimaano",
        },
        {
          id: 50,
          parent: 48,
          title: "Ernesto Nesty S. Flores",
          jd_pdf: "Mobile App Developer",
          description: "Mobile Application Developer",
          image: photos.b2,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Mobile Application Developer",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Gino Rafael Dimaano",
        },
        {
          id: 51,
          parent: 7,
          title: "Daniel S. Cansino",
          jd_pdf: "IT Specialist",
          description: "IT Specialist",
          image: photos.b6,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: IT Specialist",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Gino Rafael Dimaano",
        },
        {
          id: 52,
          parent: 48,
          title: "Alvin Mira Castillo",
          jd_pdf: "Web Developer",
          description: "Web Developer",
          image: photos.b1,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Web Developer",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Gino Rafael Dimaano",
        },
        {
          id: 53,
          parent: 48,
          title: "Rafael Joseph E. Zaide",
          jd_pdf: "Junior Developer",
          description: "Junior Developer, RND",
          image: photos.b4,
          templateName: "contactTemplate2",
          jobDescription0: "Position Title: Junior Developer, RND",
          jobDescription1: "Reporting Relationships",
          jobDescription2: "Accountable to :  Las Casas Gaming, Inc.",
          jobDescription3:
            "Reports to : Gino Rafael Dimaano",
        },
      ],
    };

    return (
      <>
        <div className="teams">
          <h1>Las Casas Gaming, Inc.</h1>
          <h2>Organization Chart</h2>
          <button className="modal-download-button"
            onClick={() =>
              this.downloadFileAtURL(ORGCHART)
            }>
            Download Organization Chart
          </button>
          <div>
      </div>
        </div>
        <div className="org-container">
          <div className="team">
            <div>
              <OrgDiagram centerOnCursor={true} config={config} />
            </div>
          </div>
        </div>
        <Modal
  isOpen={modalIsOpen}
  onRequestClose={this.closeModal}
>
  {selectedItem && (
    <div
      style={{
        backgroundImage: 'url(/images/modal-bg1.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '8px',
      }}
    >
      <button className="modal-close-button-x" onClick={this.closeModal}>×</button>
      <h3>{selectedItem.title}</h3>
      <div className="job-description">
        <p>{selectedItem.jobDescription0}</p>
        <p>{selectedItem.jobDescription1}</p>
        <p>{selectedItem.jobDescription2}</p>
        <p>{selectedItem.jobDescription3}</p>
      </div>
      <div className="modal-buttons">
        {JOB_DESCRIPTIONS[selectedItem.jd_pdf] && (
          <button
            className="modal-download-button"
            onClick={() =>
              this.downloadFileAtURL(
                JOB_DESCRIPTIONS[selectedItem.jd_pdf]
              )
            }
          >
            Download Job Description PDF
          </button>
        )}
        {ORGCHART[selectedItem.jd_pdf] && (
          <button
            className="modal-download-button"
            onClick={() =>
              this.downloadFileAtURL(
                ORGCHART[selectedItem.jd_pdf]
              )
            }
          >
            Download Organization Chart PDF
          </button>
        )}
        {JOB_KPI[selectedItem.jd_pdf] && (
          <button
            className="modal-download-button"
            onClick={() =>
              this.downloadFileAtURL(
                JOB_KPI[selectedItem.jd_pdf]
              )
            }
          >
            Download KPI PDF
          </button>
        )}
        {/* <button
          className="modal-close-button"
          onClick={this.closeModal}
        >
          Close
        </button> */}
      </div>
    </div>
  )}
</Modal>
      </>
    );
  }
}

export default Team;
